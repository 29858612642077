import React, { Component } from 'react'
import DocumentMeta from 'react-document-meta'

import Layout from '@/layout'
import Container from '@/container'
import TermsPrivacyWrapper from '@/terms-privacy-wrapper'

import intlMessages from 'i18n/rekisteriseloste'

class Rekisteriseloste extends Component {
  render() {
    return (
      <Layout>
        <DocumentMeta
          title={intlMessages.documentTitle.defaultMessage}
          extend
        />
        <Container>
          <TermsPrivacyWrapper>
            <h1>Choicely asiakasrekisteri</h1>
            <h3>Rekisterinpitäjä</h3>
            <p>
              Choicely Ltd.
              <br />
              Y-tunnus 25620708
            </p>
            <p>
              Osoite:
              <br />
              Mikonkatu 17
              <br />
              00100 Helsinki
              <br />
              Puhelin:
              <br />
              Sähköposti: info@choicely.com
            </p>
            <h3>Rekisteriasioita hoitava henkilö</h3>
            <p>
              Kaius Meskanen
              <br />
              Sähköposti: kaius@choicely.com
            </p>
            <h3>Rekisterin nimi</h3>
            <p>Choicely Ltd. asiakasrekisteri</p>
            <h3>Henkilötietojen käsittelyn tarkoitus</h3>
            <p>Asiakkaan tietoja voidaan käsitellä seuraaviin tarkoituksiin:</p>
            <ul className="list-disc">
              <li>Asiakassuhteen hoitaminen ja kehittäminen</li>
              <li>Asiakastapahtumien varmentaminen</li>
              <li>Tilastointi ja analysointi</li>
              <li>Muut vastaavat käyttötarkoitukset</li>
            </ul>
            <h3>Rekisterin tietosisältö</h3>
            <p>
              Etu- ja sukunimi
              <br />
              Sukupuoli
              <br />
              Ikä
              <br />
              Lokaatio
              <br />
              Intressit
              <br />
              Tiedot henkilön ilmoittamista markkinointiluvista ja kielloista
            </p>
            <h3>Säännönmukaiset tietolähteet</h3>
            <p>Rekisteröidyn itsensä antamat/päivittämät tiedot.</p>
            <h3>
              Säännönmukaiset tietojen luovutukset ja tietojen siirto EU:n tai
              Euroopan talousalueen ulkopuolelle
            </h3>
            <p>
              Tietoja voidaan luovuttaa suoramarkkinointitarkoituksiin, mikäli
              rekisteröity on sen erikseen hyväksynyt. Henkilötietoja ei
              luovuteta EU:n tai ETA:n ulkopuolelle.
            </p>
            <h3>Rekisterin suojauksen periaatteet</h3>
            <p>
              Rekisteriä säilytetään sähköisesti ja suojattuna asianmukaisesti
              ulkopuolisilta palomuurein ja muin teknisin suojakeinoin.
              Ainoastaan nimetyillä henkilöillä on oikeus käyttää ja ylläpitää
              rekisterin tietoja. Käyttäjiä sitoo vaitiolovelvollisuus.
            </p>
            <h3>Tarkastus-, korjaus- ja kielto-oikeus</h3>
            <p>
              Asiakkaalla on oikeus tarkastaa, mitä häntä koskevia tietoja
              rekisteriin on tallennettu. Tarkastuspyyntö tulee lähettää
              kirjallisesti ja allekirjoitettuna rekisterinpitäjän
              rekisteriasioita hoitavalle henkilölle. Tarkastuspyyntö voidaan
              esittää myös henkilökohtaisesti yllä mainitussa osoitteessa.
              Tarkastuspyyntöä henkilökohtaisesti esitettäessä on asiakkaalla
              oltava mukana henkilöllisyystodistus.
            </p>
            <p>Choicely vastaa tietojen tarkastuspyyntöihin kirjallisesti.</p>
            <p>
              Jos asiakkaan tiedoissa on virheitä, asiakas voi esittää
              rekisterinpitäjälle pyynnön virheen korjaamiseksi.
            </p>
            <p>
              Asiakkaalla on oikeus kieltää tietojensa käyttäminen
              suoramarkkinointiin tai markkina- ja mielipidetutkimuksiin
              ilmoittamalla siitä rekisterinpitäjälle.
            </p>
          </TermsPrivacyWrapper>
        </Container>
      </Layout>
    )
  }
}

export default Rekisteriseloste

export default {
  documentTitle: {
    id: 'rekisteriselostePage.documentTitle',
    defaultMessage: 'Rekisteriseloste - Choicely'
  },
  documentDescription: {
    id: 'rekisteriselostePage.documentDescription',
    defaultMessage: 'Rekisteriseloste - Choicely'
  }
}
